import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/core"

import DropdownTabs from "../ui/dropdown-tabs"

import {
  dataPriceInstruments,
  getPriceInstrument,
} from "./data-price-instruments"
import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  max-width: 900px;
`

const Tabs = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media (max-width: 768px) {
    display: none;
  }
`

const Tab = styled.div`
  width: 100%;
  color: rgba(245, 245, 245, 0.7);
  padding-top: 23px;
  padding-bottom: 23px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  font-family: "Open Sans";
  background: #16191a;
  cursor: pointer;
  transition: color 1s;
  &:hover {
    color: #ffffff;
    transition: 0.3s;
  }
`

const SelectTab = styled(Tab)`
  background: #000000;
  font-weight: bold;
  color: #ffffff;
`

const dropdownTabsContainerCSS = css`
  display: none;
  background: #000000;
  border: none;
  @media (max-width: 768px) {
    display: block;
  }

  span {
    color: white;
  }
  ul {
    background: #000000;
  }
  li {
    color: rgba(245, 245, 245, 0.7);
    border-bottom: 0.5px solid #272727;
  }
`

const Table = styled.table`
  width: 100%;
  min-width: 660px;
  background: #ffffff;
  color: #000000;
  text-align: center;
  border-collapse: collapse;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans";
  @media (max-width: 768px) {
    min-width: 0px;
  }
`

const Thead = styled.thead`
  background: #f5f5f5;
`

const Tr = styled.tr`
  height: 50px;
`

const Th = styled.th`
  vertical-align: middle;
  height: 40px;
  color: #000000;
  font-size: 12px;
  line-height: 16px;
  font-family: "Open Sans";
`

const Td = styled.td`
  border-top: 0.5px solid #e0e0e0;
`

const Button = styled.a`
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: bold;
  height: 25px;
  line-height: 14px;
  margin: 0 5px;
  padding-bottom: 6px;
  padding-top: 6px;
  text-decoration: none;
  width: 78px;
`

const BuyButton = styled(Button)`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  &:hover {
    background: linear-gradient(180deg, #46a457 0%, #46a457 100%);
  }
`

const SellButton = styled(Button)`
  background: #e76a6a;
  &:hover {
    background: #ca5757;
  }
`

const getMarket = (marketSelected: any) => {
  const market = dataPriceInstruments.filter(
    (priceInstruments: any) => priceInstruments.value === marketSelected.value
  )

  if (market) return market[0]
  return null
}

const getNormalizeMarket = (priceInstruments: any) => {
  return {
    value: priceInstruments.value,
    text: priceInstruments.name,
    className: priceInstruments.className,
  }
}

const PriceInstruments = () => {
  const queryString = useQueryString().queryString
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  const [marketSelected, setMarketSelected] = useState({
    value: "0",
    text: "Divisas",
  })
  const [markets, setMarkets] = useState<IMarkets>({})
  useEffect(() => {
    const fetchData = () => {
      dataPriceInstruments.forEach(async (priceInstruments: any) => {
        await getPriceInstrument(priceInstruments, markets, setMarkets)
        if (marketSelected.value === "0" && priceInstruments.value === "1") {
          setMarketSelected(getNormalizeMarket(priceInstruments))
        }
      })
    }
    fetchData()
  }, [])

  const market = getMarket(marketSelected)

  const onClick = (orderType: string, nemo: string) => {
    trackEvent(`home:price-instruments:${orderType}`, {
      mc_origen: mcOrigen,
      instrument: nemo,
    })
  }

  return (
    <Wrapper>
      <Global
        styles={{
          ".hide-mobile": {
            "@media (max-width: 530px)": {
              display: "none",
            },
          },
          ".hide-tablet": {
            "@media (max-width: 768px)": {
              display: "none",
            },
          },
        }}
      />
      <Container>
        {marketSelected.value !== "0" && (
          <>
            <Tabs>
              {dataPriceInstruments.map((priceInstruments: any) => {
                const ElementRender =
                  priceInstruments.value === marketSelected.value
                    ? SelectTab
                    : Tab
                return (
                  <ElementRender
                    key={`inst-${priceInstruments.value}`}
                    className={priceInstruments.className}
                    onClick={() =>
                      setMarketSelected(getNormalizeMarket(priceInstruments))
                    }
                  >
                    {priceInstruments.name}
                  </ElementRender>
                )
              })}
              <Tab></Tab>
            </Tabs>
            <DropdownTabs
              tabs={dataPriceInstruments.map((priceInstruments: any) =>
                getNormalizeMarket(priceInstruments)
              )}
              selectedTab={marketSelected}
              onClick={(selectedTab: any) => setMarketSelected(selectedTab)}
              containerCSS={dropdownTabsContainerCSS}
            />
          </>
        )}
        {market && markets[marketSelected.value] && (
          <Table>
            <Thead>
              <tr>
                {market.instruments.head.map((item: any) => (
                  <Th
                    key={`th-key-${item.key}`}
                    css={item.css}
                    className={item.class}
                  >
                    {item.title}
                  </Th>
                ))}
                <Th className="hide-tablet" css={{ width: "200px" }} />
              </tr>
            </Thead>
            <tbody>
              {markets[marketSelected.value] &&
                markets[marketSelected.value].map(
                  (row: { [key: string]: string }) => (
                    <Tr key={`tr-key-${row.nemo}`}>
                      {market.instruments.head.map((item: any) => (
                        <Td
                          key={`td-key-${item.key}`}
                          css={item.css}
                          className={item.class}
                        >
                          {row[item.key]}
                        </Td>
                      ))}
                      <Td className="hide-tablet">
                        <BuyButton
                          href={`https://onboarding.capitaria.com/login${queryString}`}
                          target="_blank"
                          className={`gtm-btn-buy-${row.nemo}`}
                          onClick={() => onClick("buy", row.nemo)}
                        >
                          Comprar
                        </BuyButton>
                        <SellButton
                          href={`https://onboarding.capitaria.com/login${queryString}`}
                          target="_blank"
                          className={`gtm-btn-sell-${row.nemo}`}
                          onClick={() => onClick("sell", row.nemo)}
                        >
                          Vender
                        </SellButton>
                      </Td>
                    </Tr>
                  )
                )}
            </tbody>
          </Table>
        )}
      </Container>
    </Wrapper>
  )
}

export default PriceInstruments
